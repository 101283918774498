export const config={
    app:{
            host:"https://bulut.bonbon.dev",
            port: 3001
        },
    api:{
        host:"https://bulut.bonbon.dev/api",
        port: 3001
    },
    kvk:{
        host:"https://bulut.bonbon.dev/kvk",
        port: 3001
    },
    payments:{
        host:"https://bulut.bonbon.dev/payments",
        port: 3001
    },
    ws:{
        host:"wss://bulut.bonbon.dev/ws",
        port: 3001
    },
    webpack: {
        host: "https://bulut.bonbon.dev:8080",
        port: 8080
    },
    token: "AIzaSyCdYmiOMYeEl-35AJeu5s12jEHbuVofhQU"
    }
